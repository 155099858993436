@import 'assets/stylesheets/variables.scss';
.report-wrapper {
  display: grid;
  height: calc(100vh - 60px);
  grid-template-rows: 60px 74px calc(100% - 134px);

  .tabs-wrapper {
    margin: auto 40px;
  }
  .reports-data {
    display: grid;
    grid-template-rows: 60px calc(100% - 60px);
    overflow: scroll;
    -ms-overflow-style: none;
    margin: 0 40px 0 40px;
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }

    .data-availability-text {
      align-self: flex-end;
      :first-child {
        font-weight: 600;
      }
      p {
        color: $colorInactiveDarkGrey;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }
  }

  .reports-data-table {
    p {
      color: $colorPrimaryDark;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: fit-content;
      margin: 24px 0;
    }

    thead {
      :first-child {
        padding-bottom: 8px;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 0px 16px 16px 0;
    }
    tbody {
      display: block;
      border: none;
      background: $colorWhite;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px 16px 16px 4px;
      border: 1px solid $colorDisabledGrey;
    }

    th {
      color: $colorInactiveDarkGrey;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      height: fit-content;
      padding-left: 21px;
    }

    tr {
      display: grid;
      grid-template-columns: 70% 10% 20%;

      height: fit-content;
    }

    tr:last-child td:last-child {
      border: 2px solid colorDisabledGrey;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    tr:first-child td:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    td {
      border: 1px solid $colorDisabledGrey;
      padding: 16px !important;
    }

    .no-report-message {
      font-size: 16px;
      font-weight: 400;
      color: $colorInactiveDarkGrey;
    }
    .download-icon-wrapper {
      display: flex;
      justify-content: center;
      .download-click::before {
        font-size: 20px;
        color: $colorPrimary;
      }
    }
    .pagination-wrapper {
      margin-top: 26px;
    }
  }
  .report-audit-trails-wrapper {
    display: grid;
    grid-template-rows: 60px calc(100% - 60px);
    .report-audit-trails-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 24px;
      .icon-download:before {
        font-size: 20px;
      }
    }
  }
}

@media print {
  .report-wrapper {
    height: auto;
    display: block;
  }
  .reports-data {
    display: block !important;
  }
  .report-audit-trails-wrapper {
    height: 100%;
    display: block;
  }
}

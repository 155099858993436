@import 'assets/stylesheets/variables.scss';

.home-page-container {
  // margin: 0 15px;
  // padding-inline: 8px;
  display: flex;
  flex-direction: column;

  .horizontal-line {
    border: 1px solid $colorInactiveGrey;
    margin: 0 -8px;
    &:last-child {
      border: 0;
    }
  }
  .dashboard-navigation-container {
    border: 1px solid $colorPrimaryDark;
    // margin-bottom: 16px;
    border-radius: 4px;
    // margin-top: -5px;

    .dashboard-navigation-item {
      height: 40px;
      border-right: 1px solid $colorPrimaryDark;
      color: $colorPrimaryDark;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @media screen and (min-width: 769px) and (max-width: 1048px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &:last-child {
      border-right: none;
    }
    &.disabled {
      color: $colorDisabledGrey;
      cursor: default;
    }

    .icon {
      position: relative;
      top: 2px;
    }

    .manage-patient-advocate-icon:before {
      top: 6px;
    }
  }
}
.dashboard-body-containers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  column-gap: 32px;
  row-gap: 32px;
  background-color: $colorSecondary;
  padding: 40px;

  @media screen and (min-width: 1191px) {
    height: calc(100% - 60px);
  }
  .container-title-row {
    display: flex;
    padding-bottom: 24px;
    justify-content: space-between;
    .secondary-btn-appointments-modified {
      border-radius: 20px 0 0 20px;
    }
    .secondary-btn-surveys-modified {
      border-radius: 0 20px 20px 0;
    }
    .secondary-btn-appointments-modified.active {
      background-color: $colorPrimary;
      color: $colorWhite;
    }
    .secondary-btn-surveys-modified.active {
      background-color: $colorPrimary;
      color: $colorWhite;
    }
    .container-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 18px;
      color: $colorPrimaryDark;
      .add-custom-filters-btn {
        font-size: 12px;
        font-weight: 400;
        color: $colorWhite;
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;

        &:hover {
          background-color: $colorPrimary;
          border: none;
        }

        .icon {
          position: relative;
          top: 1px;

          &:before {
            font-size: 20px;
          }
        }
      }
    }
    .create-list-text {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
        font-size: 15px;
      }
    }
  }
  .no-visits-message {
    padding: 24px 0 48px 0;
    color: $colorInactiveGrey;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
.patient-followup-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .calendar-area {
    height: 217px;
    padding: 24px 24px 0 24px;
  }
  .calendar-horizontal-line {
    border: 1px solid $colorInactiveGrey;
    margin: 16px -8px;
    &:last-child {
      border: 0;
    }
  }
  .patient-card-container {
    display: flex;
    flex-direction: column;
    // height: calc(100% - 12px - 60px - 28px - 220px - 28px);
    overflow: auto;
    margin-top: 10px;
    font-size: 12px;
    line-height: 18px;
    overflow: auto;
  }
}

.track-patients-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 16px;
  .track-patient-filter-container {
    display: flex;
    flex-direction: column;
    .title-area {
      padding: 24px 24px 0 24px;
    }
    .track-patient-filter-title {
      padding-bottom: 16px;
    }
    .track-patients-results-container {
    }
  }

  .track-patient-title {
    font-size: 13px;
    font-weight: 600;
    color: $colorInactiveDarkGrey;
    display: flex;
    border-bottom: 1px solid $colorDisabledGrey;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 12px 48px 12px 24px;
  }
}

.filter-back-button-container {
  padding-bottom: 25px;
  padding-left: 20px;
  padding-top: 20px;
  position: relative;

  .filters-back-btn {
    font-size: 16px;
    font-weight: 300;
    color: $colorPrimary;
    position: absolute;
    top: 5px;
    bottom: 10px;
    cursor: pointer;

    &:hover {
      color: $colorPrimaryDark;
    }

    .icon {
      position: relative;
      top: 1px;

      &:before {
        font-size: 18px;
      }
    }
  }
}

.no-padding {
  padding: 0px;
}

.search-container {
  width: 48%;
  margin: 0 1%;
  float: left;
  position: relative;
  margin-bottom: 20px;
}

.button-container {
  width: 48%;
  margin: 0 1%;
  float: left;
}

.procedure-select,
.location-select {
  // border: none;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid $colorDisabledGrey;
  margin-bottom: 10px;
  font-size: 14px;
}

.procedure-select:focus,
.location-select:focus {
  border-color: $colorDisabledGrey;
  box-shadow: none;
}

.procedure-date-info {
  padding: 5px 10px;
  background-color: $colorDisabledGrey;
  font-size: 12px;
  margin: 15px 0;
  color: $colorInactiveGrey;
  border-radius: 4px;
}

.add-patient-modal {
  min-height: 320px;

  .modal-subheader-content {
    background: $colorPrimaryLight;
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding-left: 5px;
    line-height: 40px;

    @media screen and (max-width: 767px) {
      margin-top: -15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    .steps-count {
      display: inline-block;
      width: 25px;
      line-height: 23px;
      border: 1px solid $colorPrimary;
      text-align: center;
      border-radius: 50%;
    }

    .active-step-count {
      background: $colorSecondaryDark;
    }

    .separation {
      margin: 0px 3px;
    }
  }

  .icon-font-a-check-mark:before,
  .icon-font-a-check-box:before {
    color: $colorPrimary;
  }
  .check-box-label {
    position: relative;
    top: -4px;
    left: 4px;
    font-size: 12px;
  }
}
.show-only-in-print {
  visibility: hidden;
  display: none;
}
@media print {
  body * {
    visibility: hidden;
  }
  .print-overflow-visible {
    overflow: visible;
  }
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
  .img-cell {
    width: 90px;
    height: 100px;
    padding: 10px 0px 10px 10px;
  }
  img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }
  .show-only-in-print {
    visibility: visible;
    display: inline;
  }
  .all-border {
    border: 1px solid $colorDisabledGrey;
  }
  .show-only-in-print.patient-procedure-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
    overflow: visible !important;
    .hide-in-print {
      display: none;
    }
  }
  .logo-in-print {
    padding: 10px 0px 10px 10px;
  }
  .index-in-print {
    vertical-align: middle;
    line-height: 90px;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    align-content: flex-end;
  }
  .patient-card-in-print {
    padding: 0;
  }
  .topborder {
    border-top: 1px solid $colorDisabledGrey;
    margin: 10px 12px;
  }
  .icons-container {
    & > div[role='button'] {
      width: 100%;
    }
  }
}

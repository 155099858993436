@import 'assets/stylesheets/variables.scss';
.tab-menu-wrapper {
  display: flex;
  align-self: center;
  height: 60px;
  flex-grow: 1;
  flex-basis: 0;
  .tab-wrapper {
    flex: 1;
    height: fit-content;
    margin: auto 0;
    .btn-left-tab {
      border-radius: 20px 0 0 20px;
    }
    .btn-center-tab {
      border-radius: 0px;
    }
    .btn-right-tab {
      border-radius: 0 20px 20px 0;
    }
    .active {
      background-color: $colorPrimary;
      color: $colorWhite;
    }
  }
}

@import 'assets/stylesheets/variables.scss';

.payments-page-container {
  display: grid;
  grid-template-rows: 60px auto;
  height: calc(100vh - 60px);
  overflow: hidden;
  .payments-page-data-container {
    display: grid;
    grid-template-rows: 40px calc(100% - 40px - 32px) 32px;
    align-items: center;
    height: calc(100vh - 60px - 60px);
    padding: 24px 40px;
    .payments-page-data-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .payment-page-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
        form {
          width: 356px;
          .search-form {
            display: flex;
            flex-direction: row;
            border: 1px solid $colorDisabledGrey;
            border-radius: 4px;
            height: 40px;
            padding: 4px;
            align-items: center;
            input {
              border: none;
            }
            .icon::before {
              top: 0px;
              font-size: 18px;
            }
          }
        }
        .refresh-text {
          font-size: 12px;
          color: $colorInactiveGrey;
        }
      }
      .icon-download {
        font-size: 24px;
      }
    }
    .payments-page-data-content {
      overflow: auto;
      height: 100%;
      padding: 16px 0px;
    }
  }
}

.payment-filters {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 0px 8px;
  .filters-body {
    overflow: hidden;
    display: flex;
    flex-flow: wrap;
    gap: 16px;
    justify-content: space-around;
    .filter-group {
      width: 300px;
      padding: 8px 0px;
      .filter-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: $colorInactiveDarkGrey;
      }
      .sub-filter-outer {
        padding-right: 4px;
        border: 1px solid $colorDisabledGrey;
        border-radius: 8px;
      }
      .sub-filter-group {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        height: 200px;
        padding: 8px 16px;
        overflow-y: scroll;
      }

      .sub-filter-group::-webkit-scrollbar {
        width: 6px;
      }

      .sub-filter-group::-webkit-scrollbar-thumb {
        background-color: $colorDisabledGrey;
        border-radius: 4px;
      }
    }
  }
  .filter-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0px 8px;
    .right-side-buttons {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
    }
  }
}

@import './variables';
@import './mixins.scss';

.fullwidth {
  width: 100%;
  height: 100%;
  // background-color: red;
  overflow-y: scroll;
}

.clr {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.copyright-content {
  font-size: 10px;
  margin-top: 10px;
}

.height-10 {
  height: 10px;
}

.height-15 {
  height: 15px;
}

.no-padding {
  padding: 0px;
}

.padding-left {
  padding: 0px 0px 0px 15px;
}

.padding-right {
  padding: 0px 15px 0px 0px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-margin {
  margin: 0 !important;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

// //
// Application Colors
// //
.primary-text-color {
  color: $colorPrimary;
}

.text-red {
  color: $colorAlertNegative !important;
}

.app-green {
  color: $colorAlertPositive;
}

.app-orange {
  color: $colorAlertNeutral;
}

.app-grey {
  color: $colorInactiveGrey;
}

.app-red {
  color: $colorAlertNegative;
}

.app-light-grey {
  color: $colorDisabledGrey;
}

.app-link {
  color: $colorPrimary;
  font-weight: 500;
}

.app-theme {
  color: $colorPrimaryDark;
}

.aligned-icon {
  &:before {
    top: 7px;
  }
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: $colorAlertNegative;
  font-size: 12px;
  font-weight: 300;
  margin: 0px !important;
}

.has-error .form-control {
  border: 2px solid $colorAlertNegative !important;
}

.help-block {
  font-weight: 300;
}

// //
// Events
// //
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-disabled {
  cursor: no-drop;
}

.hide {
  display: none;
}

.show {
  display: block;
}

// //
// Application Icons
// //
.icon-label {
  margin-left: 10px;
}

// //////
// Bootstrap Modal Changes
// //////
.modal-open {
  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal-content {
  border: none;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
  /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-dialog {
  .modal-header {
    background-color: $colorPrimary;
    color: $colorWhite;
    border-radius: 5px 5px 0 0;
    padding: 5px 15px;

    .modal-title {
      font-weight: 100;
      font-size: 24px;
      line-height: 40px;

      @media screen and (max-width: 572px) {
        font-size: 20px;
      }
    }

    .close {
      opacity: 1;
      background-image: url('../images/icclose.png');
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: right;
      width: 30px;
      height: 40px;

      > span {
        display: none;
      }
    }

    .has-success {
      color: limegreen;
    }

    .has-error {
      color: $colorAlertNegative;
    }
  }

  .modal-body {
    padding: 20px;

    @media screen and (max-width: 425px) {
      padding: 15px;
    }

    p {
      margin-bottom: 15px;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .modal-footer {
    clear: both;
    border: 0;
    padding: 0px 20px 20px 20px;

    .btn {
      min-width: 100px;
      margin-left: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.primary-modal {
  .modal-content {
  }

  .modal-dialog .modal-header .close {
    background-image: url('../images/icclose.png');
    background-size: 25px;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .modal-dialog .modal-header {
    background: $colorPrimary;
    color: $colorWhite;
    padding: 5px 20px;
  }

  .modal-dialog .modal-header .modal-title {
    font-weight: 300;
  }

  .delete-text {
    font-weight: 300;
  }
}

// //////
// Form fields
// //////
.form-group {
  display: flex !important;
  row-gap: 8px;
  flex-direction: column;
  margin-bottom: 0 !important;

  @media screen and (max-width: 572px) {
    margin-bottom: 0px;
  }

  content: ' ';
}

.required:after {
  content: '*';
  color: $colorAlertNegative;
}

label {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 400;
  color: $colorInactiveDarkGrey;
  text-transform: uppercase;
}

.form-container {
  // border: 1px solid #D2D2EB;
  // border-radius: 10px;
  // background: $colorSecondary;
  // padding: 20px 20px 25px 20px;
  margin-top: 50px;
}

.input-container {
  .form-control[disabled] {
    background-color: $colorDisabledGrey !important;
    color: $colorInactiveGrey;
  }
}

.Select.is-disabled > .Select-control {
  background-color: $colorDisabledGrey !important;
}

.date-field {
  .form-control[disabled] {
    color: inherit !important;
    height: 33px;
    padding: 8px;
    background-color: #eee;
    margin: -8px;
    margin-top: -8.5px;
    margin-right: 0;
    width: 226px;
  }
}

.rc-time-picker {
  input {
    width: 100% !important;
  }

  .icon::before {
    position: absolute;
    right: 5px;
    top: 7px;
    font-size: 16px;
    color: $colorPrimary;
  }

  input[disabled] {
    background-color: $colorDisabledGrey !important;
    color: $colorInactiveGrey !important;
  }
}

input {
  width: 100%; // border: none;
  border: 1px solid $colorDisabledGrey;
  line-height: 32px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

input:focus {
  outline: none;
}

textarea {
  width: 100%;
  border-color: $colorDisabledGrey;
  border-radius: 4px;
  height: 100px;
  padding: 10px;
}

textarea.form-control {
  width: 100%;
  height: 100px;
  padding: 10px;
  white-space: pre-wrap;
}

.date-picker-field {
  padding-left: 0px;
  padding-right: 0px;
  color: $colorBlack !important;

  .form-control {
    border: none !important;
    border-radius: 0;
    box-shadow: none !important;
    height: 27px;
    margin-top: -6px;
    padding: 0px;
    margin-bottom: 0px !important;
    font-size: 14px !important;
  }

  .form-control[readonly] {
    background-color: $colorWhite;
  }

  input {
    cursor: pointer;
    width: 100%;
    line-height: 30px !important;
    margin-bottom: 5px !important;
    border-radius: 0;
    font-size: 16px !important;
    font-weight: 300 !important;

    @media screen and (max-width: 768px) {
      font-size: 14px !important;
    }
  }
}

select {
  font-size: 16px !important;
  font-weight: 300 !important;

  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }

  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid $colorInactiveGrey !important;
  border-radius: 0 !important;

  &.form-control {
    background-image: url('../images/dropdownicon.png') !important;
    padding: 0px;
    padding-right: 25px;
  }
}

.Select {
  background: none;

  &:focus {
    outline: none;
  }

  .Select-value {
    border-color: $colorPrimary !important;
    background: none !important;
    border-radius: 15px !important;
    font-size: 14px !important;
    line-height: 28px !important;

    span {
      border: none;
      color: $colorPrimary;
    }
  }
}

.Select-value-icon,
.Select-value-label {
  line-height: 20px;
  height: 20px;
}

.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: transparent !important;
}

.date-field {
  box-shadow: none !important;
  border: 2px solid $colorDisabledGrey !important;
  border-radius: 0px;
  padding-left: 8px;
  width: 32%;

  input {
    background-image: url('../images/iccalendar.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    background-position-x: 98%;

    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}

.time-field {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid $colorInactiveGrey !important;
  border-radius: 0px;
  padding-left: 0px;

  input {
    background-image: url('../images/ictime.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    background-position-x: 98%;

    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}

.rdtCounter {
  .rdtBtn {
    color: $colorPrimary;
  }
}

// //////
// Fonts
// //////
.font-11 {
  font-size: 11px;
}

.not-found-container {
  background-image: url('../../assets/images/mms404.gif');
  background-repeat: no-repeat;
  height: calc(100vh);
  width: 100%;
  background-size: cover;
  background-position: center;
}

.error-container {
  background-image: url('../../assets/images/mms500mobilevr.gif');
  background-repeat: no-repeat;
  height: calc(100vh);
  width: 100%;
  background-size: cover;
  background-position: center;

  @media screen and (min-width: 568px) {
    background-image: url('../../assets/images/mms500mobilehr.gif');
  }

  @media screen and (min-width: 768px) {
    background-image: url('../../assets/images/mms500ipadvr.gif');
  }

  @media screen and (min-width: 1024px) {
    background-image: url('../../assets/images/mms500.gif');
  }
}

.switch {
  height: 25px;

  .slider.round {
    display: inline-block;
    height: 24px;
    width: 55px;

    &:before {
      width: 20px;
      height: 20px;
      bottom: 2px;
    }
  }
}

.Select-input {
  height: 28px !important;
}

.app-smaller-container {
  display: block; // height: calc(100vh);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  div {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
  }

  .image-container {
    img {
      width: 100px;
    }
  }

  .btn {
    font-size: 18px;
  }

  .log-out-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    //position: absolute;
    width: 100%;
    padding: 0px;
  }

  .btn-separator {
    border-bottom: 1px solid $colorDisabledGrey;
    width: 100%;
    margin-bottom: 20px;
  }

  .info-container {
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
}

.fab {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: $colorPrimaryGradient;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  font-size: 30px;
  font-weight: 300;
  color: $colorWhite;
  text-align: center;
  line-height: 38px;
  position: fixed;
  right: 25px;
  bottom: 80px;
}

.fab:hover {
  transform: scale(1.05);
}

.gray_dot {
  &.dark {
    border: 2px solid $colorInactiveGrey;
  }

  display: inline-block;
  border: 2px solid $colorDisabledGrey;
  border-radius: 50%;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.vertical-bar {
  border-right: 2px solid $colorDisabledGrey;
  width: 0px;
}

.text-align-center {
  text-align: center !important;
}

.modal-dialog {
  @media screen and (max-width: 572px) {
    width: 90%;
  }
}

.footer-content-container {
  .text {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }

  .phone-container {
    margin-top: 15px;

    .phone {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .email-container {
    margin-top: 15px;

    .email {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .aws-link-container {
    display: flex;
    margin-top: 16px;
    flex-direction: row;
    justify-content: center;
    column-gap: 3px;

    .link {
      font-size: 14px;
      font-weight: 500;
      margin-left: 10px;
    }
    .icon {
      &:before {
        font-size: 20px;
        color: $colorPrimary;
      }
    }
  }

  .icon {
    &:before {
      font-size: 16px;
      color: $colorPrimary;
    }
  }
}

.caret {
  color: $colorPrimaryDark;
}

.app-container {
  display: grid;
  grid-template-rows: 60px calc(100% - 60px);
  height: 100vh;

  .outer-header {
    .header-container {
      position: fixed;
      top: 0;
      background: $colorWhite;
      width: 100%;
      z-index: 99;

      @media screen and (max-width: 572px) {
        display: none;
      }
    }

    @media screen and (max-width: 572px) {
      display: none;
    }
  }

  @media screen and (max-width: 572px) {
    grid-template-rows: none;
  }
}

.custom-form-label {
  font-weight: 100;
  font-size: 12px;
  color: $colorInactiveGrey;

  @media screen and (max-width: 572px) {
    margin-top: 20px;
  }
}

.multi-select {
  .Select-arrow {
    background-image: none;
    width: 0px;
    height: 0px;
    border-style: outset;
  }

  .Select-value {
    margin-top: 3px;

    & > span {
      float: right;
    }
  }

  .Select-multi-value-wrapper {
    width: 95%;

    .Select-placeholder {
      font-weight: 300;
      font-size: 14px;
    }

    .Select-value {
      .Select-value-label {
        line-height: 18px;
        font-size: 12px;
        font-weight: 300;
      }

      .Select-value-icon {
        padding-top: 0 !important;
      }
    }
  }

  .Select-clear-zone {
    display: inline-block;
    background-image: url('../../assets/images/icrefreshblue.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right;
    float: right;
    width: 15px;
    height: 16px;
    top: calc(50% - 9px);
    position: absolute;
    right: 30px;
  }

  .Select-clear {
    display: none;
  }

  .Select-option {
    background-image: url('../../assets/images/icgraytick.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right;
    background-position-x: 98%;

    &.is-selected {
      background-color: $colorWhite;
      color: $colorInactiveGrey;
      background-image: url('../../assets/images/ictickblue.png');
    }
  }
}

.answer-feedback {
  .icon:before {
    font-size: 30px;
    top: 10px;

    @media screen and (max-width: 572px) {
      font-size: 35px;
    }
  }
}

.border {
  border: 1px solid;
}

.tab-details-title {
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid $colorDisabledGrey;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

#patient-details-tab-pane-7 {
  .tab-details-title {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
  }
}

pre {
  border: none;
  background-color: transparent;
}

.no-data-label {
  color: $colorDisabledGrey;
}

.light-font {
  font-weight: 300;
}

.dark-font {
  font-weight: 500;
}

.tip {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $colorPrimary;
  position: absolute;
  top: 24px;
  left: 7px;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  /* Add any other necessary styles */
}

.tip-top::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $colorPrimary transparent;
}

.tip-left::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $colorPrimary transparent transparent;
}

.tip-right::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent $colorPrimary;
}

.tip-bottom::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: rotate(180deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $colorPrimary transparent;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-content {
  display: inline-block;
  width: 120px;
  background: $colorPrimary;
  color: $colorWhite;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 30px;
  // left: 0;
  font-size: 10px;
  line-height: 14px !important;
  word-break: normal;
  white-space: normal;
}

.align-left {
  text-align: left !important;
}

.vertical-line-separator {
  margin: 0 5px;
  border-left: 1px solid $colorBlack;
  height: 100%;
  width: 1px;
}

.btn-plain-text {
  color: $colorPrimary;
  border: none;
  background: none;
}

.count-bubble {
  background: $colorPrimary;
  width: 20px;
  height: 20px;
  color: $colorWhite;
  margin-left: 5px;
  border-radius: 50%;
}

.outcomes-sharing-modal-body {
  height: 475px;
  overflow-y: auto;
}

.outcomes-sharing-modal {
  .navigate-button-container {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-explanation {
      font-size: 12px;

      &:first-of-type {
        margin-top: 10px;
      }
    }

    .button-separator {
      margin: 20px 0px;
      font-size: 16px;
      font-style: italic;
      font-weight: 100;
    }
  }

  .modal-info {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .text-block {
    display: block;
  }

  .action-button-container {
    .btn-default {
      margin-right: 20px;
    }
  }

  .history-item {
    margin-bottom: 15px;
    border-bottom: 1px solid $colorInactiveGrey;
    padding-bottom: 15px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .person {
      display: block;
      font-size: 18px;
      text-transform: capitalize;
    }

    .text-small {
      font-weight: 300;
      font-size: 13px;
    }

    .text-dark-gray,
    .vertical-pipe {
      color: $colorInactiveGrey;
    }

    .vertical-pipe {
      font-weight: 300;
      font-size: 12px;
      margin: 0 4px;
    }

    .text-gray {
      color: $colorInactiveGrey;
    }

    .text-blue {
      color: $colorPrimaryDark;
    }

    .outcomes-report-message {
      font-size: 14px;
      color: $colorBlack;
      margin: 14px 0;
    }

    .outcomes-report-type {
      margin-bottom: 3px;
    }

    .shared-by {
      margin-right: 6px;
    }
  }
}

.link-disabled {
  cursor: not-allowed;
  color: $colorDisabledGrey !important;
  outline: none;

  &.icon {
    &:before {
      color: $colorDisabledGrey !important;
    }
  }
}

.multi-select__control--is-focused,
.multi-select__control--menu-is-open {
  box-shadow: 0 0 0 1px $colorPrimary !important;
}

.full-width {
  width: 100%;
}

.text-reply {
  margin-top: 10px;
}

// defining customised classes

.font-size-xxs {
  font-size: 8px;
}

.font-size-xs {
  font-size: 10px;
}

.font-size-s {
  font-size: 12px;
}

.font-size-m {
  font-size: 14px;
}

.font-size-l {
  font-size: 16px;
}

.font-size-xl {
  font-size: 18px;
}

.font-size-xxl {
  font-size: 20px;
}

.font-size-header-xs {
  font-size: 22px;
}

.font-size-header-s {
  font-size: 24px;
}

.font-size-header-m {
  font-size: 26px;
}

.font-size-header-l {
  font-size: 28px;
}

.font-size-header-xl {
  font-size: 30px;
}

//font weight
.font-weight-100 {
  font-weight: 100;
}

.ffont-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

// padding classes
.padding-2 {
  padding: 2px;
}

.padding-4 {
  padding: 4px;
}

.padding-6 {
  padding: 6px;
}

.padding-8 {
  padding: 8px;
}

.padding-10 {
  padding: 10px;
}

.padding-12 {
  padding: 12px;
}

.padding-14 {
  padding: 14px;
}

.padding-16 {
  padding: 16px;
}

.padding-18 {
  padding: 18px;
}

.padding-20 {
  padding: 20px;
}

.padding-22 {
  padding: 22px;
}

.padding-24 {
  padding: 24px;
}

.padding-26 {
  padding: 26px;
}

.padding-28 {
  padding: 28px;
}

.padding-30 {
  padding: 30px;
}

.padding-32 {
  padding: 32px;
}

.padding-34 {
  padding: 34px;
}

.padding-36 {
  padding: 36px;
}

.padding-38 {
  padding: 38px;
}

.padding-40 {
  padding: 40px;
}

.padding-42 {
  padding: 42px;
}

.padding-44 {
  padding: 44px;
}

.padding-46 {
  padding: 46px;
}

.padding-48 {
  padding: 48px;
}

.padding-50 {
  padding: 50px;
}

.padding-52 {
  padding: 52px;
}

//padding left
.padding-left-2 {
  padding-left: 2px;
}

.padding-left-4 {
  padding-left: 4px;
}

.padding-left-6 {
  padding-left: 6px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-12 {
  padding-left: 12px;
}

.padding-left-14 {
  padding-left: 14px;
}

.padding-left-16 {
  padding-left: 16px;
}

.padding-left-18 {
  padding-left: 18px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-22 {
  padding-left: 22px;
}

.padding-left-24 {
  padding-left: 24px;
}

.padding-left-26 {
  padding-left: 26px;
}

.padding-left-28 {
  padding-left: 28px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-32 {
  padding-left: 32px;
}

.padding-left-34 {
  padding-left: 34px;
}

.padding-left-36 {
  padding-left: 36px;
}

.padding-left-38 {
  padding-left: 38px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-left-42 {
  padding-left: 42px;
}

.padding-left-44 {
  padding-left: 44px;
}

.padding-left-46 {
  padding-left: 46px;
}

.padding-left-48 {
  padding-left: 48px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-left-52 {
  padding-left: 52px;
}

//padding right
.padding-right-2 {
  padding-right: 2px;
}

.padding-right-4 {
  padding-right: 4px;
}

.padding-right-6 {
  padding-right: 6px;
}

.padding-right-8 {
  padding-right: 8px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-12 {
  padding-right: 12px;
}

.padding-right-14 {
  padding-right: 14px;
}

.padding-right-16 {
  padding-right: 16px;
}

.padding-right-18 {
  padding-right: 18px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-22 {
  padding-right: 22px;
}

.padding-right-24 {
  padding-right: 24px;
}

.padding-right-26 {
  padding-right: 26px;
}

.padding-right-28 {
  padding-right: 28px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-32 {
  padding-right: 32px;
}

.padding-right-34 {
  padding-right: 34px;
}

.padding-right-36 {
  padding-right: 36px;
}

.padding-right-38 {
  padding-right: 38px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-42 {
  padding-right: 42px;
}

.padding-right-44 {
  padding-right: 44px;
}

.padding-right-46 {
  padding-right: 46px;
}

.padding-right-48 {
  padding-right: 48px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-right-52 {
  padding-right: 52px;
}

//padding top
.padding-top-2 {
  padding-top: 2px;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-top-6 {
  padding-top: 6px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-12 {
  padding-top: 12px;
}

.padding-top-14 {
  padding-top: 14px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-top-18 {
  padding-top: 18px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-22 {
  padding-top: 22px;
}

.padding-top-24 {
  padding-top: 24px;
}

.padding-top-26 {
  padding-top: 26px;
}

.padding-top-28 {
  padding-top: 28px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-32 {
  padding-top: 32px;
}

.padding-top-34 {
  padding-top: 34px;
}

.padding-top-36 {
  padding-top: 36px;
}

.padding-top-38 {
  padding-top: 38px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-42 {
  padding-top: 42px;
}

.padding-top-44 {
  padding-top: 44px;
}

.padding-top-46 {
  padding-top: 46px;
}

.padding-top-48 {
  padding-top: 48px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-52 {
  padding-top: 52px;
}

//padding bottom
.padding-bottom-2 {
  padding-bottom: 2px;
}

.padding-bottom-4 {
  padding-bottom: 4px;
}

.padding-bottom-6 {
  padding-bottom: 6px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-12 {
  padding-bottom: 12px;
}

.padding-bottom-14 {
  padding-bottom: 14px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-bottom-18 {
  padding-bottom: 18px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-22 {
  padding-bottom: 22px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.padding-bottom-26 {
  padding-bottom: 26px;
}

.padding-bottom-28 {
  padding-bottom: 28px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-32 {
  padding-bottom: 32px;
}

.padding-bottom-34 {
  padding-bottom: 34px;
}

.padding-bottom-36 {
  padding-bottom: 36px;
}

.padding-bottom-38 {
  padding-bottom: 38px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-42 {
  padding-bottom: 42px;
}

.padding-bottom-44 {
  padding-bottom: 44px;
}

.padding-bottom-46 {
  padding-bottom: 46px;
}

.padding-bottom-48 {
  padding-bottom: 48px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-52 {
  padding-bottom: 52px;
}

// MUI Global Styling
.MuiFormHelperText-root.Mui-error.MuiFormHelperText-contained {
  margin: 0;
}

.btn-primary-disabled {
  background: $colorDisabledGrey;
  color: $colorInactiveGrey !important;
  border: none !important;
  outline: none;

  &:hover {
    background: $colorDisabledGrey !important;
    color: $colorInactiveGrey !important;
    border: none !important;
    outline: none;
  }

  &:focus {
    background: $colorDisabledGrey !important;
    color: $colorInactiveGrey !important;
    border: none !important;
    outline: none;
  }
}

.text-button {
  color: $colorPrimary !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  &:hover {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  }

  &:active {
    color: $colorPrimaryDark !important;
  }

  &:disabled {
    color: $colorDisabledGrey !important;
  }
}

.single-select__indicator.single-select__dropdown-indicator,
.multi-select__indicator.multi-select__dropdown-indicator {
  position: relative;
  right: 4px;
}

.multi-select__control,
.single-select__control {
  border: 1px solid $colorInactiveGrey !important;
  border-radius: 4px;

  &:hover {
    border-color: none !important;
  }
  &:active {
    border-color: $colorPrimary !important;
  }

  &:disabled {
    border-color: $colorDisabledGrey !important;
  }
}

.modal-footer-buttons {
  display: flex;
  flex-direction: column;
  width: 300px;
  column-gap: 16px;
}

.modal-content-icon {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  .MuiSvgIcon-root {
    font-size: 14px;
    stroke-width: 2px;
  }

  .modal-content-icon-circle {
    width: 23.33px;
    height: 23.33px;
    border-radius: 23.33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.icon-circle-body {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 190px;
}

.icon-circle {
  width: 23.33px;
  height: 23.33px;

  display: flex;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    width: 20px;
    height: 20px;
  }

  .inside-icon {
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      width: 6px;
      height: 6px;
      position: relative;
      top: -23px;
      left: 0px;
      color: $colorWhite;
    }
  }
}

.icon-circle-body {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 10px;
  text-align: center;
}

.MuiBox-root {
  border: none !important;
}

.single-select__indicator-separator,
.multi-select__indicator-separator {
  background-color: inherit !important;
}

// drop-down when tab is inactive
.single-select__indicator.single-select__dropdown-indicator,
.multi-select__indicator.multi-select__dropdown-indicator {
  color: $colorInactiveGrey;

  &:disabled {
    color: $colorDisabledGrey !important;
  }
}

.css-1qajzci-indicatorContainer:hover {
  color: $colorInactiveGrey !important;
}

.multi-select_indicator.multi-select_dropdown-indicator.css-yibq0y-indicatorContainer {
  color: $colorPrimary;
}

.multi-select_control.multi-select_control--is-focused.multi-select_control--menu-is-open {
  color: $colorPrimary;
  .multi-select__indicator.multi-select__dropdown-indicator {
    color: $colorPrimary;
  }
}
// single-select__indicator.single-select__dropdown-indicator
// .css-1ff3jt6-control {
//   border-color: $colorPrimary;
// }

// drop-down when tab is active
// .single-select__indicator.single-select__dropdown-indicator,
// .multi-select__indicator.multi-select__dropdown-indicator{
//   color: $colorPrimary;
// }

.MuiOutlinedInput-input.MuiInputBase-input {
  font-size: 16px !important;
  line-height: 32px !important;
  padding-right: 48px !important;
}

.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-outlined.MuiFormLabel-root {
  font-size: 16px !important;
  text-transform: none;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl {
  font-size: unset !important;
}

.MuiCalendarPicker-root {
  font-size: 16px !important;

  .MuiTypography-root.MuiTypography-caption {
    font-size: 16px !important;
  }

  .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    font-size: 16px !important;
  }

  .css-1v994a0 {
    font-size: 16px !important;
  }

  .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    font-size: 16px !important;
  }
}

.MuiTypography-root.MuiTypography-overline {
  font-size: 16px !important;
}

.MuiButton-root.MuiButton-text.MuiButtonBase-root {
  font-size: 16px !important;
}

.select-dropdown {
  .MuiFormControl-root.MuiTextField-root {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 56px;
    }
  }
}

.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined {
  margin: 0 14px;
  font-size: 30px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  .MuiList-root.MuiMenu-list {
    display: flex;
    flex-direction: column;
    padding: 0;

    li {
      height: 48px;
      border-bottom: 2px solid $colorInactiveGrey;
      background-color: inherit;
      padding: 0 8px;
      justify-content: flex-start;
      font-size: 16px;
    }

    li:last-child {
      border-bottom: none;
    }
  }
}

[role='button'] {
  cursor: default;
}

@import '/src/assets/stylesheets/variables.scss';
.report-admin-title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: $colorPrimaryDark;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
    margin-top: 30px;
  }
}
.reports-admin-wrapper {
  width: 100vw;
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .safety-review-data-btn {
    width: 382px !important;
    height: 48px !important;
    background-color: $colorSecondary !important;
  }
  .last-generated-msg {
    color: $colorInactiveDarkGrey;
    margin: 21px 46px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
